.c-modal--cookies {
    .btn,
    .c-btn,
    .button {
        font-weight: normal;
        text-decoration: underline;
        text-align: center;
        background-color: transparent;
        color: var(--primary-color-darker) !important;
        transform: none !important;
        transition: none;
        border: none !important;
        padding: 0;

        &:before,
        &:after {
            content: none !important;
        }

        &:hover,
        &:active,
        &:focus {
            // color: var(--primary-color-darker);
            background-color: transparent;
            text-decoration: none;
        }
    }

    .btn.btn-primary,
    .c-btn.c-btn--primary {
        border-radius: 5px;
        color: var(--white) !important;
        background-color: var(--primary-color);
        font-weight: 700;
        text-decoration: none;
        padding: .75rem 2rem;

        &:hover,
        &:active,
        &:focus {
            color: var(--white);
            background-color: var(--primary-color-darker);
        }
    }

    .btn.btn-secondary,
    .c-btn.c-btn--secondary {
        opacity: .5 !important;
    }

    .btn.btn--close,
    .c-btn.c-btn--close {
        background-color: transparent;
        height: auto;
        padding: 0;
        width: auto !important;
    }
}
