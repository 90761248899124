:root {
    --black: #111315;
    --white: #ffffff;

    --primary-color: #2d4156;
    --primary-color-darker: #243445;

    --secondary-color: #eaeff2;
    --secondary-color-lighter: #f2f5f7;
    --secondary-color-darker: #e7edf0;
    --secondary-color-darkest: #dae3e8;
}

::-moz-selection {
    background: var(--secondary-color-darkest) !important;
}

::selection {
    background: var(--secondary-color-darkest) !important;
}
