.c-modal {
    .c-modal__header,
    .c-modal__body,
    .c-modal__footer {
        border: none;
    }

    .c-modal__content {
        border: none;
    }
}

.c-modal--cookies {
    color: var(--primary-color) !important;
    font-size: .85em;
    line-height: normal;
    z-index: 1079 !important;

    /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none;
    scrollbar-width: none;

    /* Hide scrollbar for Chrome, Safari and Opera */
    &::-webkit-scrollbar {
        display: none;
    }

    p {
        &:last-of-type {
            margin-bottom: 0;
        }
    }

    a {
        color: var(--primary-color);
        font-weight: 700;
        text-decoration: underline;

        &:hover,
        &:active,
        &:focus {
            text-decoration: none;
        }
    }

    .c-modal__title {
        font-size: 1.125em;
        font-weight: 700;
        color: var(--primary-color);
    }

    .c-modal--preferences__footer {
        .c-btn--primary {
            width: 100% !important;

            @media(min-width: breakpoint(lg)) {
                width: auto !important;
            }
        }
    }
}
