.c-toggle {
    position: absolute !important;
    top: 0 !important;
    bottom: 0 !important;
    right: 0 !important;
    margin: auto !important;

    input[type="checkbox"] {
        &:focus {
            outline: none;
            box-shadow: none;
        }
    }
}

.c-toggle--consent {
    z-index: 1000;

    input[type="checkbox"] {
        position: relative;
        height: 1rem;
        width: calc(1rem + 1rem);
        border-radius: 2rem;
        // margin-right: 1rem !important;

        &:focus {
            border-color: rgba(0,0,0,.25);
            background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="-4 -4 8 8"><circle r="3" fill="rgba%280, 0, 0, 0.25%29"/></svg>');
        }

        &:checked {
            background-color: var(--primary-color);
            border-color: var(--primary-color);
            background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="-4 -4 8 8"><circle r="3" fill="%23ffffff"/></svg>');
        }
    }
}
