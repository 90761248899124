.c-accordion {
    color: var(--primary-color);

    .c-accordion__item {
        border: 0;

        &:not(:last-of-type) {
            margin-bottom: .5rem;
        }
    }
}

.c-accordion--cookies {
    .c-accordion__item {
        button {
            position: relative !important;
            font-size: .85rem;
            font-weight: 700;
            color: var(--primary-color);
            background-color: var(--secondary-color);
            border-radius: 10px !important;
            padding-left: 3rem;
            box-shadow: none;

            &:not(.collapsed) {
                border-bottom-left-radius: 0 !important;
                border-bottom-right-radius: 0 !important;

                &:before {
                    transform: rotate(180deg);
                }
            }

            &:after {
                content: none;
            }

            &:before {
                content: '\f078';
                position: absolute;
                font-family: 'Font Awesome 5 Pro';
                font-size: .85rem;
                left: 1.25rem;
            }

            &:focus {
                outline: none;
                box-shadow: none;
            }
        }
    }

    .c-accordion__body {
        background-color: var(--secondary-color-lighter);
        border-bottom-left-radius: 10px !important;
        border-bottom-right-radius: 10px !important;
    }
}
