.c-toast--cookies {
    background-color: var(--white);
    transition: all 350ms;

    i {
        color: var(--secondary-color-darkest);
    }

    &:hover {
        transform: scale(1.05);
    }
}
